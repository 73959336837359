@import '~bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #aaabb8;
  background: linear-gradient(to bottom right, #25274d, #29648a);
}

header {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  padding: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 5px solid #aaabb8;
  font-weight: bold;
}

header h1 {
  font-size: 3rem;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

header p {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  header h1 {
    font-size: 2rem;
  }

  header p {
    font-size: 1rem;
  }
}

.interactive-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.cursor-circle {
  position: absolute;
  width: 70px;
  height: 70px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: transform 0.05s ease-out;
  display: none;
}

.bubble {
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.8),
    rgba(46, 156, 202, 0.5)
  );
  border-radius: 50%;
  pointer-events: none;
  animation-fill-mode: forwards;
  will-change: transform;
}

/* Spread upward */
@keyframes bubble-spread-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-400px);
    opacity: 0;
  }
}

/* Spread downward */
@keyframes bubble-spread-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(400px);
    opacity: 0;
  }
}

/* Spread leftward */
@keyframes bubble-spread-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-400px);
    opacity: 0;
  }
}

/* Spread rightward */
@keyframes bubble-spread-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(400px);
    opacity: 0;
  }
}

/* Spread top-left to bottom-right */
@keyframes bubble-spread-diagonal-top-left {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(400px) translateY(400px);
    opacity: 0;
  }
}

/* Spread bottom-left to top-right */
@keyframes bubble-spread-diagonal-bottom-left {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(400px) translateY(-400px);
    opacity: 0;
  }
}

/* Spread top-right to bottom-left */
@keyframes bubble-spread-diagonal-top-right {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-400px) translateY(400px);
    opacity: 0;
  }
}

/* Spread bottom-right to top-left */
@keyframes bubble-spread-diagonal-bottom-right {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-400px) translateY(-400px);
    opacity: 0;
  }
}

.articles-list {
  margin-top: 20px;
}

.instructions {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  border: 5px solid #aaabb8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.instructions p {
  margin: 10px 0;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .instructions p {
    font-size: 1.2rem;
  }
}

.filter-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
}

.filter-bar .filter-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;
}

.filter-bar .filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 300px;
}

.filter-bar input,
.filter-bar select {
  padding: 10px;
  border: 3px solid #aaabb8;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
}

.filter-bar button {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  color: #ffffff;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  width: 100%;
  max-width: 150px;
  border: 3px solid #aaabb8;
}

.filter-bar button:hover {
  background: linear-gradient(to bottom right, #25274d, #29648a);
  transform: scale(1.05);
}

/* Medium Screen Adjustments */
@media (min-width: 768px) {
  .filter-bar {
    flex-direction: column;
  }

  .filter-bar .filter-inputs {
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }

  .filter-bar .filter-buttons {
    justify-content: center;
    flex-wrap: nowrap;
  }
}

/* Small Screen Adjustments */
@media (max-width: 480px) {
  .filter-bar {
    gap: 20px;
  }

  .filter-bar .filter-inputs {
    flex-direction: column;
    align-items: center;
  }

  .filter-bar .filter-buttons {
    flex-direction: column;
    align-items: center;
  }

  .filter-bar button {
    width: 100%;
    max-width: 250px;
  }
}

/* Clear All Button */
.clear-all-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.clear-all-button button {
  background-color: #aaabb8;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  border: 3px solid #464866;
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.clear-all-button button:hover {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  transform: scale(1.05);
}

.article-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #ffffff, #f4f4f9);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom, #ffffff, #eaf6fb);
}

.article-card:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  pointer-events: none;
  z-index: -1;
}

.article-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #2e9cca;
  font-weight: bold;
  text-align: center;
}

.article-card p {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #464866;
  text-align: center;
}

.button-link {
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(135deg, #2e9cca, #29648a);
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  text-align: center;
  border: 3px solid #aaabb8;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.button-link:hover {
  transform: scale(1.1);
  background: linear-gradient(to bottom right, #25274d, #29648a);
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  text-align: center;
  margin-top: 20px;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #2e9cca;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-results-heading {
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  margin: 20px 0;
  font-weight: bold;
}


@media (max-width: 768px) {
  .search-results-heading {
    font-size: 1.8rem;
  }

  .article-card {
    padding: 10px;
  }
}

.no-articles {
  text-align: center;
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 20px;
}

/* Back to Top Button */
.back-to-top {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.back-to-top button {
  background: linear-gradient(to bottom right, #25274d, #29648a);
  color: #ffffff;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 3px solid #aaabb8;
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.back-to-top button:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #2e9cca, #29648a);
}

.section-separator {
  margin: 30px 0;
  border: 5px solid #2e9cca;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.view-all-section {
  text-align: center;
  margin: 40px 0;
  padding: 20px;
  background: linear-gradient(135deg, #2e9cca, #29648a);
  border-radius: 15px;
  color: white;
  font-weight: bold;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  border: 5px solid #aaabb8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.view-all-section p {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.view-all-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.view-all-buttons button {
  background: linear-gradient(to bottom right, #25274d, #29648a);
  color: white;
  width: 220px;
  padding: 12px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: 3px solid #aaabb8;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.view-all-buttons button:hover {
  transform: scale(1.05);
  background: linear-gradient(135deg, #2e9cca, #29648a);
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.pagination button {
  background: linear-gradient(to bottom right, #25274d, #29648a);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 3px solid #aaabb8;
  font-weight: bold;
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

.pagination button:hover {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  transform: scale(1.05);
}

mark {
  background-color: #f0e68c;
  color: inherit;
  line-height: 1.6;
  font-weight: bold;
}

::selection {
  background: #f0e68c;
}

.dedication {
  background: linear-gradient(135deg, #2e9cca, #29648a);
  color: #ffffff;
  padding: 30px;
  margin-top: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  border: 5px solid #aaabb8;
  position: relative;
  overflow: hidden;
}

.dedication h2 {
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.dedication p {
  margin: 15px 0;
  line-height: 1.8;
  font-size: 1.2rem;
  z-index: 1;
  position: relative;
}

@media (max-width: 768px) {
  .dedication {
    padding: 20px;
    margin: 20px 0;
  }

  .dedication h2 {
    font-size: 1.5rem;
  }

  .dedication p {
    font-size: 1rem;
    margin: 10px 0;
  }
}

footer {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #2e9cca, #29648a);
  color: #ffffff;
  border-radius: 10px;
  font-size: 1rem;
  border: 5px solid #aaabb8;
  font-weight: bold;
  line-height: 1.8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
}

.footer-section {
  margin-bottom: 20px;
}

footer a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: 15px 25px;
  border-radius: 10px;
  background: linear-gradient(to bottom right, #25274d, #29648a);
  border: 3px solid #aaabb8;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.2s ease,
    background-color 0.3s ease;
}

footer a:hover {
  color: #ffffff;
  background: linear-gradient(135deg, #2e9cca, #29648a);
  transform: scale(1.05);
}

.email {
  margin-top: 10px;
  font-size: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 12px 20px;
  background: linear-gradient(to bottom right, #25274d, #29648a);
  border-radius: 10px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

@media (max-width: 768px) {
  footer {
    font-size: 0.9rem;
    padding: 15px;
  }

  footer a {
    padding: 12px 20px;
    font-size: 0.9rem;
  }

  .button-container {
    gap: 10px;
  }

  .email {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}

/* Apply a yellow outline to all focusable elements */
:focus {
  outline: 2px solid #f1c40f;
  outline-offset: 2px;
}
